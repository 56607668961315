import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DynamicFilters from "./DynamicFilters";
import CustomCard from "../../components/CustomCard";
import CustomAccordion from "../../components/CustomAccordion";
import FiltersHead from "./FiltersHead";
import moment from "moment";
import Filters from "../../components/FilterChips";
import "../MainCss.scss";

import { fetchFilterDataAction } from "./action";
import SnackBar from "../../components/SnackBar";
import { useDispatch, useSelector } from "react-redux";
import useAction from "../../utils/useAction";

const styles = (theme) => ({
  headerCard: {
    ...theme.content.card,
    width: "100%",
    height: "100%",
    overflow: theme.content.overflow.visible,
  },
  hierarchyHeaderCard: {
    ...theme.content.card,
    width: "100%",
    height: "100%",
    padding: "1%",
    display: "flex",
    justifyContent: "space-between",
    overflow: theme.content.overflow.visible,
  },
  hierarchyCard: {
    ...theme.content.card,
    maxWidth: 280,
    minWidth: 280,
    height: "100%",
    padding: "2%",
    overflow: theme.content.overflow.visible,
  },
  filterPage: {
    ...theme.content.card,
    width: "100%",
    height: "100vh",
    paddingTop: "0.75%",
    overflow: theme.content.overflow.visible,
  },
  container: {
    ...theme.content.flexStyles.flexRow,
    ...theme.content.flexStyles.flexAlignBetweenCenter,
  },
});
const useStyles = makeStyles(styles);

const FiltersList = (props) => {
  const {
    openLoader,
    closeLoader,
    screenName,
    filter_id,
    isFilterButtonNotRequired,
    filter_label,
    grouping,
    expandGroup,
  } = props;
  // const { isRadio, radioData, isClusterFilter, clusterFilterOptions } =
  // 	isFilterNotRequired;
  const invokeAction = useAction();
  const dispatch = useDispatch();
  const { filterMenuData, filterMasterValuesData } = useSelector(
    (state) => state.filter
  );
  const {
    tableInfo,
    dependentInfo,
    formEditedValues,
    columnsInfo,
  } = useSelector((state) => state.home);
  const classes = useStyles();
  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const [filterMenu, setFilterMenu] = useState([]);
  const [filterMasterValues, setFilterMasterValues] = useState([]);
  const [dynamicFiltersProps, setDynamicFiltersProps] = useState([]);
  const [dynamicFiltersOptions, setDynamicFiltersOptions] = useState([]);
  const [independentApiCalls, setIndependentApiCalls] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [selectedFilterChips, setSelectedFilterChips] = useState({});
  const [selectedFilter, setSelectedFilter] = useState("");
  const [displayMessage, setDisplayMessage] = useState(false);
  const [message, setMessage] = useState(null);
  const [modelFilteredData, setModelFilteredData] = useState([]);
  const [initDependents, setInitDependents] = useState(true);
  const [filterOnInit, setFilterOnInit] = useState(props.filterOnInit);
  const [filtersGroup, setFiltersGroup] = useState({});
  const [alert, setAlert] = useState({
    show: false,
    message: "",
  });
  useEffect(() => {
    if (filterMenuData.length === 0) {
      openLoader();
      dispatch(
        fetchFilterDataAction({
          model_names: ["tb_filter_master_copy", "tb_filter_master_values"],
        })
      );
    }
  }, []);

  useEffect(() => {
    const filter_data = filterMenuData.filter(
      (obj) => parseInt(obj["filter_id"]) === filter_id
    );
    setFilterMenu([...filter_data]);
    setFilterMasterValues([...filterMasterValuesData]);
  }, [filterMenuData, filterMasterValuesData, filter_id]);

  const reloadFilters = async () => {
    const filterObj = {};
    await filterMenu.forEach((obj) => {
      if (obj.type === "datetime" || obj.type === "date-range") {
        const dateOption = filterMasterValues.find(
          (obj) => parseInt(obj.parent_id) === parseInt(obj.categoryid)
        );
        if (dateOption) {
          const range = dateOption.name.split(",");
          filterObj[`${obj.master_category}`] = [
            new Date(moment(range[0])),
            new Date(moment(range[1])),
          ];
        }
      } else if (obj.type === "date") {
        filterObj[`${obj.label}`] = [new Date(moment())];
      } else {
        const newData = filterMasterValues.filter(
          (childObj) =>
            parseInt(childObj.parent_id) === parseInt(obj.categoryid) &&
            childObj.is_default_value === "Y"
        );
        newData.filter((obj) => {
          obj["label"] = obj.name;
          obj["value"] = obj.name;
        });
        filterObj[`${obj.master_category}`] = newData;
        // filterObj[`${obj.master_category}`] = [];
      }
    });
    setFilterData(filterObj);
    setSelectedFilterChips({});
  };
  useEffect(() => {
    reloadFilters();
  }, [filterMasterValues]);

  useEffect(() => {
    if (Object.keys(filterData).length > 0) {
      const filtersPropsList = [];
      const filtersOptionsList = {};
      const independentApis = [];
      filterMenu.forEach((obj) => {
        let isDependentOn = filterMenu.filter((filterObj) => {
          if (
            (filterObj.is_dependent_on &&
              filterObj.is_dependent_on === obj.id) ||
            filterObj.master_category === "Season"
          ) {
            return filterObj;
          }
        });
        filtersPropsList.push({
          type: obj.type,
          placeholder: `Select ${obj.master_category}`,
          state: filterData[`${obj.master_category}`],
          mappingKey: obj.master_category,
          label: obj.label,
          required: obj.is_required === "TRUE",
          is_dependent_on: obj.is_dependent_on,
          id: obj.categoryid,
          infotipEnabled: obj.infotip_enabled === "1",
          infotip: obj.infotip,
          isDependentOn,
          grid: obj.grid,
          ...obj,
        });
        filtersOptionsList[`${obj.master_category}Options`] = [];
        if (obj.is_dependent === "FALSE") {
          independentApis.push({
            optionsMappingKey: obj.master_category,
            UrlName: obj.categoryid,
            type: obj.type,
          });
        }
      });
      setDynamicFiltersProps(filtersPropsList);
      setDynamicFiltersOptions(filtersOptionsList);
      setIndependentApiCalls(independentApis);
    }
  }, [filterData]);
  const updateState = (params, dropdownOptions) => {
    // console.log("change",options,params,dynamicFiltersProps.filter(obj=> obj.mappingKey === Object.keys(params)[0] && obj['is_store_required'] === 'TRUE'))
    const formEditedInfo = dynamicFiltersProps.filter(
      (obj) =>
        obj.mappingKey === Object.keys(params)[0] &&
        obj["is_store_required"] === "TRUE"
    );
    let reqParams = {
      ...filterData,
      ...params,
    };
    setFilterData(reqParams);
    props.updateParentState(reqParams);
    if (formEditedInfo.length > 0) {
      let filterParams = { ...formEditedInfo[0] };
      const dropdownName = Object.keys(params)[0];
      let formEditing = {};
      let dropdownParams = {};
      let value = params[`${dropdownName}`].map((obj) => obj.value);
      if (
        filterParams.hasOwnProperty("toggle_other_keys") &&
        filterParams["toggle_other_keys"]
      ) {
        let toggleKeys = filterParams.toggle_other_keys.split(",");
        toggleKeys.forEach((key) => {
          dropdownParams[`${key}`] = false;
        });
      }

      formEditing[`${Object.keys(params)[0]}`] = params[`${dropdownName}`].map(
        (obj) => obj.value
      )[0];
      if (dropdownName === "Channel") {
        formEditing[`${Object.keys(params)[0]}`] = params[
          `${dropdownName}`
        ].map((obj) => obj.value);
      }
      if (dropdownOptions) {
        dropdownOptions.forEach((obj) => {
          let values = params[`${dropdownName}`].map((obj) => obj.value);
          if (values.length === 1) {
            if (value == obj.value) {
              dropdownParams[`${obj.value}`] = true;
            } else {
              if (dependentInfo[`${obj.value}`]) {
                dropdownParams[`${obj.value}`] = false;
              }
            }
          } else {
            if (values.length > 1) {
              dropdownParams[`${values.join("And")}`] = true;
              dropdownParams[`${obj.value}`] = false;
            }
          }

          // dropdownParams[`${value}`] = dependentInfo[`${value}`] ? !dependentInfo[`${value}`] : true;
        });
      } else {
        dropdownParams[`${value}`] = dependentInfo[`${value}`]
          ? !dependentInfo[`${value}`]
          : true;
      }

      dispatch({
        type: "FORM_EDITED_VALUES",
        payload: formEditing,
      });
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: dropdownParams,
      });
      dispatch({
        type: "SELECTED_DROPDOWN_VALUES",
        payload: params,
      });
    }
  };
  const onReset = () => {
    reloadFilters();
    setFiltersExpanded(false);
    let params = {};
    params[`filter-button-click`] = false;
    params[`default-key`] = true;
    dispatch({
      type: "DEPENDENT_COMPONENTS",
      payload: params,
    });
    props.onReset();
  };
  const setupFilterDependents = (params, data) => {
    const obj = {};
    Object.keys(params).forEach((category) => {
      obj[category] = new Set(params[category].map((option) => option?.value));
    });
    dispatch({
      type: "FILTERS_DEPENDENTS",
      payload: { [data.filter_id]: obj },
    });
  };
  const appliedFiltersAction = async () => {
    for (let i = 0; i < filterMenu.length; i++){
      const item = filterMenu[i]
      if (item.mandatoryCount) {
        const selectedOptionsCount = Object.values(filterData)[i].length;
        if (selectedOptionsCount < item.mandatoryCount) {
          setAlert({
            show: true,
            message: item.warning,
          });
          return;
        }
      }
    }
    if (alert.show === true) {
      setAlert({
        show: false,
        message: "",
      });
    }
    props.appliedFilters(filterData);
    let reqObj = {};
    Object.keys(filterData).filter((key, index) => {
      if (filterData[`${key}`].length > 0) {
        reqObj[`${key}`] = {
          label: key,
          section: filterData[`${key}`].map((obj) => {
            if (obj instanceof Date && !isNaN(obj))
              return obj.toLocaleDateString("en-US");
            return obj?.value;
          }),
        };
      }
    });
    let params = {};
    params[`filter-button-click`] = !dependentInfo[`filter-button-click`];
    params[`default-key`] = !dependentInfo[`default-key`];
    if (props?.dependentOnFilters) {
      params[`${props?.dependentOnFilters}`] = !dependentInfo[
        `${props?.dependentOnFilters}`
      ];
    }
    dispatch({
      type: "DEPENDENT_COMPONENTS",
      payload: params,
    });
    setSelectedFilterChips(reqObj);
    setFiltersExpanded(false);
    if (props?.isDependentRequired) {
      setupFilterDependents(filterData, props);
    }
    if (props?.action) {
      invokeAction(props.action);
    }
  };
  useEffect(() => {
    if (Object.keys(filterData).length > 0) {
      if (props?.isDependentRequired) {
        if (initDependents) {
          setupFilterDependents(filterData, props);
          setInitDependents(false);
        }
      }
      if (filterOnInit) {
        setFilterOnInit(false);
        setTimeout(appliedFiltersAction, 0);
      }
    }
  }, [filterData]);
  useEffect(() => {
    if (grouping && dynamicFiltersProps && dynamicFiltersProps.length > 0) {
      const group = {};
      dynamicFiltersProps.forEach((obj) => {
        const title = obj?.group || "Filters";
        if (group.hasOwnProperty(title)) {
          group[title].push(obj);
        } else {
          group[title] = [obj];
        }
      });
      setFiltersGroup(group);
    }
  }, [dynamicFiltersProps]);

  const handleMessageClose = () => {
    setDisplayMessage(false);
    setMessage(null);
  };

  function updateChips(summary) {
    setFilterData((prev) => {
      const params = { ...prev };
      Object.keys(params).forEach((key) => {
        if (key in summary) {
          const labels = new Set(summary[key].section);
          const options = params[key];
          params[key] = options.filter((item) => labels.has(item.label));
        } else {
          params[key] = [];
        }
      });
      return params;
    });
    setSelectedFilterChips(summary);
  }
  return (
    <div className={"impact-filter-container"}>
      {/* <LoadingOverlay loader={loader}> */}
      {displayMessage && (
        <SnackBar
          message={message}
          variant="warning"
          handleClose={handleMessageClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      )}

      {isFilterButtonNotRequired ? (
        <>
          {props.filterBlockNotRequired ? (
            <>
              {dynamicFiltersProps.length > 0 && (
                <DynamicFilters
                  filtersList={dynamicFiltersProps}
                  options={dynamicFiltersOptions}
                  hierarchyApiCalls={independentApiCalls}
                  openLoader={props.openLoader}
                  closeLoader={props.closeLoader}
                  updateParentState={updateState}
                  appliedFilters={appliedFiltersAction}
                  onReset={onReset}
                  screenName={screenName}
                  filterMasterValues={filterMasterValues}
                  isFilterNotRequired={true}
                  spacing={props.spacing}
                  filtersGroup={filtersGroup}
                  grouping={grouping}
                  expandGroup={expandGroup}
                />
              )}
            </>
          ) : (
            <>
              <CustomCard>
                <div
                  style={{
                    marginLeft: "25px",
                    marginTop: "5px",
                    marginRight: "25px",
                  }}
                  className={"impact-filters-withoutbutton"}
                >
                  <div className="filter-container">
                    <h2>{filter_label}</h2>
                    {dynamicFiltersProps.length > 0 && (
                      <DynamicFilters
                        filtersList={dynamicFiltersProps}
                        options={dynamicFiltersOptions}
                        hierarchyApiCalls={independentApiCalls}
                        openLoader={props.openLoader}
                        closeLoader={props.closeLoader}
                        updateParentState={updateState}
                        appliedFilters={appliedFiltersAction}
                        onReset={onReset}
                        screenName={screenName}
                        filterMasterValues={filterMasterValues}
                        isFilterNotRequired={true}
                        spacing={props.spacing}
                        filtersGroup={filtersGroup}
                        grouping={grouping}
                        expandGroup={expandGroup}
                      />
                    )}
                  </div>
                  <div className="spacer" />
                  <hr
                    style={{
                      marginRight: "1.25%",
                      borderTop: "1px solid #CCC",
                    }}
                  ></hr>
                </div>
              </CustomCard>
            </>
          )}
        </>
      ) : (
        <>
          {props.expand ? (
            <Grid container spacing={2} className={"impact-filters-withbutton"}>
              <Grid
                item
                xs={12}
                className={"impact-filters-withbutton-subcontainer"}
              >
                {dynamicFiltersProps.length > 0 ? (
                  <DynamicFilters
                    hideButtons={props.hideButtons}
                    filtersList={dynamicFiltersProps}
                    options={dynamicFiltersOptions}
                    hierarchyApiCalls={independentApiCalls}
                    openLoader={props.openLoader}
                    closeLoader={props.closeLoader}
                    updateParentState={updateState}
                    appliedFilters={appliedFiltersAction}
                    onReset={onReset}
                    screenName={screenName}
                    filterMasterValues={filterMasterValues}
                    isFilterNotRequired={false}
                    isFilterButtonNotRequired={isFilterButtonNotRequired}
                    style={props.style}
                    spacing={props.spacing}
                    filtersGroup={filtersGroup}
                    grouping={grouping}
                    expandGroup={expandGroup}
                    {...props}
                  />
                ) : null}
              </Grid>
            </Grid>
          ) : (
            <>
              {props?.dependentOnFilters ? (
                <>
                  {dynamicFiltersProps.length > 0 ? (
                    <>
                      <CustomAccordion
                        // summary={}
                        isSelectFiltersNotRequired={true}
                        summary={
                          <FiltersHead
                            filtersExpanded={props.expand || filtersExpanded}
                            setFiltersExpanded={setFiltersExpanded}
                            screenName={screenName}
                          />
                        }
                        details={
                          <DynamicFilters
                            hideButtons={props.hideButtons}
                            filtersList={dynamicFiltersProps}
                            options={dynamicFiltersOptions}
                            hierarchyApiCalls={independentApiCalls}
                            openLoader={props.openLoader}
                            closeLoader={props.closeLoader}
                            updateParentState={updateState}
                            appliedFilters={appliedFiltersAction}
                            onReset={onReset}
                            screenName={screenName}
                            filterMasterValues={filterMasterValues}
                            isFilterNotRequired={false}
                            isFilterButtonNotRequired={
                              isFilterButtonNotRequired
                            }
                            style={props.style}
                            spacing={props.spacing}
                            filtersGroup={filtersGroup}
                            grouping={grouping}
                            expandGroup={expandGroup}
                          />
                        }
                        expanded={filtersExpanded}
                        overrideWrapperStyle={
                          props?.overrideFilterWrapper ? true : false
                        }
                      />
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  <Grid
                    container
                    spacing={2}
                    className={"impact-dynamicfilter-without-expand"}
                  >
                    <Grid
                      item
                      xs={12}
                      className={
                        "impact-dynamicfilter-without-expand-subcontainer"
                      }
                    >
                      <CustomCard
                        cardStyles={classes.headerCard}
                        sx={{ margin: 0 }}
                      >
                        {dynamicFiltersProps.length > 0 ? (
                          <>
                            <CustomAccordion
                              summary={
                                <FiltersHead
                                  filtersExpanded={
                                    props.expand || filtersExpanded
                                  }
                                  setFiltersExpanded={setFiltersExpanded}
                                  screenName={screenName}
                                />
                              }
                              details={
                                <DynamicFilters
                                  hideButtons={props.hideButtons}
                                  filtersList={dynamicFiltersProps}
                                  options={dynamicFiltersOptions}
                                  hierarchyApiCalls={independentApiCalls}
                                  openLoader={props.openLoader}
                                  closeLoader={props.closeLoader}
                                  updateParentState={updateState}
                                  appliedFilters={appliedFiltersAction}
                                  onReset={onReset}
                                  screenName={screenName}
                                  filterMasterValues={filterMasterValues}
                                  isFilterNotRequired={false}
                                  isFilterButtonNotRequired={
                                    isFilterButtonNotRequired
                                  }
                                  style={props.style}
                                  spacing={props.spacing}
                                  filtersGroup={filtersGroup}
                                  grouping={grouping}
                                  expandGroup={expandGroup}
                                  alert={alert}
                                  onAlertClose={() => {
                                    setAlert({
                                      show: false,
                                      message: "",
                                    });
                                  }}
                                />
                              }
                              expanded={props.expand || filtersExpanded}
                              overrideWrapperStyle={
                                props?.overrideFilterWrapper ? true : false
                              }
                            />
                          </>
                        ) : null}
                      </CustomCard>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}

          {!filtersExpanded && Object.keys(selectedFilterChips).length > 0 && (
            <Filters
              filtersSummary={selectedFilterChips}
              updateChips={updateChips}
            />
          )}
        </>
      )}
      {/* </LoadingOverlay> */}
    </div>
  );
};
export default FiltersList;
